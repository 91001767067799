import { useMerchantContext } from "lib/contexts/MerchantProvider";
import Bowser from "bowser";
import { FloAnalyticsPayload, AnalyticsProps } from "lib/types/analytics";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { EventPayloadType } from "lib/types/analytics";
import { postAnalytics, putRequest } from "lib/core/apiClient";
import { useUserContext } from "lib/contexts/UserProvider";
import { inIframe } from "lib/utils/helpers";
import {
  sendPaymentInfoFbqEvent,
  sendCheckoutInitiateFbqEvent,
  sendPaymentCompletedFbqEvent,
  sendAddToCartFbqEvent,
} from "lib/third-party/facebook";
import { getCartForAnalytics } from "lib/utils/analytics";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useSearchParams } from "react-router-dom";
import { AuthSource } from "lib/types/user";
import { sendAnalyticsEvent as sendAnalytics } from "./analytics";
import { getUserOrderHistory } from "lib/utils/user";

let thirdPartyAnalytics: any = {};
let channel: string = "";
let clientMetadata: any = {};
let longSessionId: string = "";

const useSendAnalyticsEvent = () => {
  const {
    state: { merchant },
  } = useMerchantContext();
  const { state } = useCheckoutContext();
  const { checkoutId, shopifySessionId, checkoutToken, checkoutView, billing, checkoutItems, segmentId } =
    state;
  const {
    state: { userLoginType, user },
  } = useUserContext();
  const [searchParams] = useSearchParams();

  const isCartPage = searchParams.get("page") === "cart" || searchParams.get("page") === "CART";

  const userAgentInfo = Bowser.parse(window?.navigator?.userAgent);

  const setThirdPartyAnalyticsData = (data: any) => {
    thirdPartyAnalytics = data;
  };

  const setCheckoutChannel = (data: string) => {
    channel = data;
  };

  const setClientMetadata = (data: any) => {
    clientMetadata = {
      brandUrl: data?.brand_url ?? "",
      clientIp: data?.client_ip ?? "",
      userAgent: data?.user_agent ?? "",
    };
  };

  const setLongSessionId = (data: string) => {
    longSessionId = data;
  };

  const sendAnalyticsEvent = ({
    eventName,
    eventFor = [analyticsTypes.SF_ANALYTICS],
    eventType,
    metaData,
    context = "checkout",
    skipState = false,
  }: AnalyticsProps) => {
    if (eventFor.indexOf(analyticsTypes.SF_ANALYTICS) > -1) {
      const eventPayload: EventPayloadType = {
        checkoutId: metaData?.checkoutId ?? checkoutId,
        tokenId: metaData?.checkoutToken ?? checkoutToken,
        uiData: {
          pageName: isCartPage ? "CART" : checkoutView,
          longSessionId: metaData?.longSessionId ? metaData.longSessionId : longSessionId,
          ...(metaData?.methodType && { paymentMethod: metaData?.methodType?.toString()?.toLowerCase() }),
          ...(metaData?.modeType && { paymentMode: metaData?.modeType?.toString()?.toLowerCase() }),
          ...(metaData?.couponData && { coupon_data: metaData?.couponData }),
          ...(metaData?.shippingData && { shippingData: metaData?.shippingData }),
          ...(metaData?.paymentData && { paymentData: metaData?.paymentData }),
          ...(metaData?.bannerData && { bannerData: metaData?.bannerData }),
          ...(metaData?.metadata && { metadata: metaData?.metadata }),
          ...(metaData?.action && { action: metaData?.action }),
          ...(metaData?.authSource && { authSource: metaData?.authSource }),
          ...(metaData?.badgesData && { badgesData: metaData?.badgesData }),
          ...(metaData?.storeDetails && { ...metaData?.storeDetails }),
          ...(metaData?.methodName && { methodName: metaData?.methodName }),
          ...(metaData?.upsellData && { upsellData: metaData?.upsellData }),
          ...(metaData?.addressData && { addressData: metaData?.addressData }),
          ...(metaData?.issueReason && { issueReason: metaData?.issueReason }),
          ...(metaData?.milestone && { milestone: metaData?.milestone }),
          ...(metaData?.milestones && { milestones: metaData?.milestones }),
          ...(metaData?.progressBarCategory && { progressBarCategory: metaData?.progressBarCategory }),
          ...(metaData?.progressBarType && { progressBarType: metaData?.progressBarType }),
          ...(metaData?.wishlistData && { wishlistData: metaData?.wishlistData }),
          ...(metaData?.itemSelectorData && { itemSelectorData: metaData?.itemSelectorData }),
          ...(segmentId && { segmentId }),
        },
        clientData: {
          browserName: userAgentInfo?.browser?.name,
          browserVersion: userAgentInfo?.browser?.version,
          osName: userAgentInfo?.os?.name,
          osVersion: userAgentInfo?.os?.version,
          osVersionName: userAgentInfo?.os?.versionName,
          platformModel: userAgentInfo?.platform?.model,
          platformType: userAgentInfo?.platform?.type,
          engineName: userAgentInfo?.engine?.name,
          engineVersion: userAgentInfo?.engine?.version,
          isIFrame: inIframe(),
          analytics: thirdPartyAnalytics,
          brandUrl: merchant?.brandUrl ?? metaData?.brandUrl,
          ...clientMetadata,
        },
        userData: metaData?.userData
          ? {
              authSource: searchParams.get("auth") ? AuthSource.CUSTOM : AuthSource.SHOPFLO,
              customerType: getUserOrderHistory(merchant?.merchantId ?? ""),
              ...metaData?.userData,
            }
          : {
              type: userLoginType,
              userId: user?.uid,
              authSource: searchParams.get("auth") ? AuthSource.CUSTOM : AuthSource.SHOPFLO,
              customerType: getUserOrderHistory(merchant?.merchantId ?? "")
            },
        cart: getCartForAnalytics(metaData?.checkoutItems ?? checkoutItems, billing),
      };

      const payload: FloAnalyticsPayload = {
        eventName: eventName,
        merchantId: merchant?.merchantId ?? metaData?.merchantId,
        sessionId: metaData?.shopifySessionId ?? shopifySessionId,
        timestamp: Date.now(),
        eventPayload: eventPayload,
        eventType: eventType,
        channel: metaData?.channel ?? channel,
      };
      try {
        sendAnalytics(payload);
        // postAnalytics(payload);
      } catch (e) {
        console.error(e);
      }
      if (
        !Boolean(skipState) &&
        [
          analyticsEvents.FLO_AUTH_LOGIN_COMPLETED,
          analyticsEvents.FLO_PAYMENT_LOADED,
          analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
        ].includes(eventName)
      ) {
        try {
          const payload = {
            state: eventName,
          };
          putRequest(`/checkout/v1/checkout/${metaData?.checkoutId ?? checkoutId}/state`, payload);
        } catch (error) {
          console.error(error);
        }
      }
    }
    if (Boolean(eventFor.includes(analyticsTypes.FACEBOOK_PIXEL))) {
      const isFbqEnabled = metaData?.isFbqEnabled ?? merchant?.analytics?.facebook?.enabled;
      const fbqPixelId = metaData?.fbqPixelId ?? merchant?.analytics?.facebook?.pixel_id;
      if (!Boolean(isFbqEnabled) || !Boolean(fbqPixelId)) return;

      const userDetails = {
        userName: metaData?.userData?.name,
        userEmail: metaData?.userData?.email,
        userPhone: metaData?.userData?.phone,
      };

      if (
        (eventName === analyticsEvents.FLO_COOKIE_LOGIN ||
          eventName === analyticsEvents.FLO_AUTH_PHONE_LOADED) &&
        context === "checkout"
      ) {
        sendCheckoutInitiateFbqEvent({
          orderTotal: metaData?.totalPayable ?? billing.total_payable ?? 0,
          lineItems: metaData?.checkoutItems ?? checkoutItems,
          currency: "INR",
          sessionId: metaData?.shopifySessionId ?? shopifySessionId,
          pixelId: fbqPixelId,
          checkoutChannel: channel,
          ...{ userDetails },
        });
      }

      if (eventName === analyticsEvents.FLO_PAYMENT_METHOD_SELECTED) {
        sendPaymentInfoFbqEvent({
          orderTotal: billing.total_payable ?? 0,
          lineItems: checkoutItems,
          currency: "INR",
          sessionId: metaData?.shopifySessionId ?? shopifySessionId,
          pixelId: fbqPixelId,
          checkoutChannel: channel,
          ...{ userDetails },
        });
      }

      if (eventName === analyticsEvents.FLO_PAYMENT_COMPLETED) {
        sendPaymentCompletedFbqEvent({
          orderTotal: metaData?.orderAmount ?? billing.total_payable ?? 0,
          lineItems: checkoutItems,
          currency: "INR",
          sessionId: metaData?.shopifySessionId ?? shopifySessionId,
          pixelId: fbqPixelId,
          checkoutChannel: channel,
          ...{ userDetails },
        });
      }

      if (eventName === analyticsEvents.FLO_ADDED_TO_CART_UI) {
        sendAddToCartFbqEvent({
          orderTotal: billing.total_payable ?? 0,
          lineItems: checkoutItems,
          currency: "INR",
          sessionId: metaData?.shopifySessionId ?? shopifySessionId,
          pixelId: fbqPixelId,
          checkoutChannel: channel,
          ...{ userDetails },
        });
      }
    }
  };
  return {
    sendAnalyticsEvent,
    setThirdPartyAnalyticsData,
    setCheckoutChannel,
    setClientMetadata,
    setLongSessionId,
  };
};
export default useSendAnalyticsEvent;
