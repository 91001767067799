import { postRequest } from "lib/core/apiClient";
import Cookies from "js-cookie";
import { constants } from "lib/utils/constants";
import { OTPChannel, OTPVerificationDetails } from "lib/types/user";
import { jwtDecode } from "jwt-decode";

type AuthPayload = {
  context: "AUTH";
};

type SSOPayload = {
  context: "SSO";
  requestId: string;
};

export type OTPDeliveryType = AuthPayload | SSOPayload;

export const sendOtpWithPhoneNumber = async (
  details: OTPVerificationDetails,
  metada: OTPDeliveryType,
  reCaptchaToken: string = "",
) => {
  if (!details?.identifier || !details?.merchantId) return;

  try {
    const payload = {
      oid: details?.identifier,
      merchant_id: details?.merchantId,
      ...(metada.context === "AUTH" && {
        skip_verification: details?.skipVerification ?? false,
        linked_user_id: details.userId ?? null,
        is_force_verification: details.forceVerification ?? false,
      }),
      ...(metada.context === "SSO" && { context: "SSO", sso_request_id: metada.requestId }),
      ...(Boolean(reCaptchaToken) && { captcha_token: reCaptchaToken }),
      // domain: details.domain ?? "",
    };
    const response = await postRequest(`/otp/send`, payload, "AUTH");
    return response;
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const verifyOtp = async (contextId: string, otp: string, skipTokenGeneration?: boolean) => {
  if (!contextId || !otp) return;

  try {
    const payload = {
      context_id: contextId,
      otp: otp,
      skip_token_generation: skipTokenGeneration,
    };

    const response = await postRequest(`/otp/verify`, payload, "AUTH");
    return response;
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const resentOtp = async (contextId: string, channel: OTPChannel) => {
  if (!contextId) return;

  try {
    const payload = {
      context_id: contextId,
      preferred_channel: channel,
    };

    const response = await postRequest(`/otp/resend`, payload, "AUTH");
    return response;
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

const isAccessTokenExpiringInNextFiveMinutesOrExpired = () => {
  const authToken = getAccessToken()
  try {
    if (!authToken) {
      return true
    }
    const decodedToken = jwtDecode(authToken);
    const tokenExpiry = decodedToken.exp ? decodedToken.exp : 0;
    const currentTime = Date.now() / 1000;
    const timeUntilExpiry = tokenExpiry - currentTime;
    if (timeUntilExpiry > 5 * 60) {
      return false;
    }
    return true;
  } catch (e) {
    console.error("Error decoding token: ", e);
    return true;
  }
};

export const refreshExpiredIdToken = async (refreshTokenId?: string, isForceRefresh?: boolean) => {
  const refreshToken = refreshTokenId ?? getRefreshToken()
  const isTokenExpiringInNextFiveMinutesOrExpired = isAccessTokenExpiringInNextFiveMinutesOrExpired();

  if (!Boolean(isForceRefresh) && !isTokenExpiringInNextFiveMinutesOrExpired) return true;

  if (!Boolean(refreshToken) && isTokenExpiringInNextFiveMinutesOrExpired) return false;

  try {
    const payload = {
      refresh_token: refreshToken,
    };

    const response = await postRequest(`/user-token/refresh`, payload, "AUTH");
    if (!response) return false;
    const newAuthToken = response.access_token;
    const newRefreshToken = response.refresh_token;
    const newAuthTokenExpiry = response.access_token_expires_at;
    const newRefreshTokenExpiry = response?.refresh_token_expires_at;
    setAuthCookies(newAuthToken, newRefreshToken, newAuthTokenExpiry, newRefreshTokenExpiry);
    setAuthCookiesInLocalStorage(newAuthToken, newRefreshToken, newAuthTokenExpiry, newRefreshTokenExpiry);
    return true;
  } catch (e: any) {
    Cookies.remove(constants.AUTH_COOKIE_CLIENT, { path: "/" });
    Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT, { path: "/" });
    localStorage.removeItem(constants.ORDER_HISTORY_COOKIE_CLIENT);
    console.error(e);
    return false;
  }
};

export const setAuthCookies = (
  idToken: string,
  refreshToken: string,
  authExpiry?: number,
  refreshExpiry?: number,
) => {
  const authExpiresDate = authExpiry && new Date(authExpiry);
  const refreshExpiresDate = refreshExpiry && new Date(refreshExpiry);

  try {
    Cookies.set(constants.AUTH_COOKIE_CLIENT, idToken, {
      expires: authExpiresDate ?? constants.AUTH_COOKIE_CLIENT_EXPIRY,
      sameSite: "none",
      secure: true,
    });
    Cookies.remove(constants.AUTH_LOGGED_OUT);

    if (Boolean(refreshToken)) {
      Cookies.set(constants.REFRESH_TOKEN_COOKIE_CLIENT, refreshToken, {
        expires: refreshExpiresDate ?? constants.REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY,
        sameSite: "none",
        secure: true,
      });
    } else {
      Cookies.set(constants.REFRESH_TOKEN_COOKIE_CLIENT, refreshToken, {
        expires: new Date(0),
        sameSite: "none",
        secure: true,
      });
      Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT);
    }
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const setAuthCookiesInLocalStorage = (
  idToken: string,
  refreshToken: string,
  authExpiry?: number,
  refreshExpiry?: number,
) => {
  const authExpiresDate = authExpiry && new Date(authExpiry);
  const refreshExpiresDate = refreshExpiry && new Date(refreshExpiry);
  localStorage.setItem(constants.AUTH_COOKIE_CLIENT, idToken);
  localStorage.setItem(constants.REFRESH_TOKEN_COOKIE_CLIENT, refreshToken);
  localStorage.setItem(constants.LOCAL_STORAGE_AUTH_COOKIE_CLIENT_EXPIRY, authExpiresDate?.toString() ?? "");
  localStorage.setItem(
    constants.LOCAL_STORAGE_REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY,
    refreshExpiresDate?.toString() ?? "",
  );
};

export const expireFloAuthCookies = () => {
  Cookies.set(constants.AUTH_COOKIE_CLIENT, "", {
    expires: new Date(0),
    sameSite: "none",
    secure: true,
  });
  Cookies.set(constants.REFRESH_TOKEN_COOKIE_CLIENT, "", {
    expires: new Date(0),
    sameSite: "none",
    secure: true,
  });
};

export const getAccessToken = () => {
  if (Boolean(Cookies.get(constants.AUTH_COOKIE_CLIENT))) {
    return Cookies.get(constants.AUTH_COOKIE_CLIENT);
  }
  const localStorageToken = localStorage.getItem(constants.AUTH_COOKIE_CLIENT);
  const localStorageTokenExpiry = localStorage.getItem(constants.LOCAL_STORAGE_AUTH_COOKIE_CLIENT_EXPIRY);
  if (Boolean(localStorageToken) && localStorageTokenExpiry) {
    const currentTime = Date.now();
    const expiryTime = new Date(localStorageTokenExpiry).getTime();
    if (currentTime < expiryTime) {
      return localStorageToken;
    }
  }
  if (Boolean(Cookies.get(constants.TWO_STEP_AUTH_COOKIE_CLIENT))) {
    return Cookies.get(constants.TWO_STEP_AUTH_COOKIE_CLIENT);
  }
  return "";
};

export const getRefreshToken = () => {
  if (Boolean(Cookies.get(constants.REFRESH_TOKEN_COOKIE_CLIENT))) {
    return Cookies.get(constants.REFRESH_TOKEN_COOKIE_CLIENT);
  }
  const localStorageRefreshToken = localStorage.getItem(constants.REFRESH_TOKEN_COOKIE_CLIENT);
  const refreshTokenExpiry = localStorage.getItem(constants.LOCAL_STORAGE_REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY);
  if (Boolean(localStorageRefreshToken) && refreshTokenExpiry) {
    const expiryTime = new Date(refreshTokenExpiry).getTime();
    const currentTime = Date.now();
    if (currentTime < expiryTime) {
      return localStorageRefreshToken;
    }
  }
  return "";
};