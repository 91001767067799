import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import {
  NonPaymentMethodType,
  PaymentGateways,
  PaymentModeType,
  PaymentProcessingType,
  PaymentStatusType,
  UnApplicablePaymentReasonType,
} from "lib/types/payments";
import { errorToast, infoToast } from "lib/utils/toasters";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { useLoader } from "lib/contexts/LoaderContext";
import { useLocale } from "lib/hooks/useLocale";
import { handlePaymentSuccessRedirection, isEmptyObj, sleep, addHttpIfMissing } from "lib/utils/helpers";
import { getPaymentMethodAction, getPaymentStatus } from "lib/core/apiMethods";
import { initializePayment, loadPgSdk, triggerSuccessEvent } from "lib/utils/payments";
import { getItems } from "lib/utils/checkout";
import { getRequest, postRequest, putRequest } from "lib/core/apiClient";
import useInterval from "lib/hooks/useInterval";
import { mutate } from "swr";

type PaymentMethodDialogType = PaymentModeType | "NONE" | "COD_ORDER_PLACING" | "COD_OTP";

const Context = createContext<PaymentContext>({ state: {}, actions: {} } as PaymentContext);

export enum ActionType {
  SET_PAYMENT_METHODS,
  SET_PAYMENT_METHOD_DIALOG,
  SET_STATE,
}

export type Action =
  | { type: ActionType.SET_PAYMENT_METHODS; payload: any[] }
  | { type: ActionType.SET_PAYMENT_METHOD_DIALOG; payload: PaymentMethodDialogType }
  | { type: ActionType.SET_STATE; payload: Partial<PaymentState> };

export interface PaymentContextState {
  paymentMethods: any[];
  isProcessingDialogVisible: boolean;
  paymentStatus: PaymentStatusType;
  openPaymentMethodDialog: PaymentMethodDialogType;
}

export interface PaymentState extends PaymentContextState {
  isPolling: boolean;
  splittedPaymentMethods: any[];
  isCancellable?: boolean;
}

export interface PaymentContext {
  state: PaymentState;
  actions: {
    setPaymentMethods: (values: any) => void;
    handlePaymentMethodSelected: (method: PaymentModeType, context?: "SPLIT_COD") => Promise<{} | void>;
    setPaymentMethodDialog: (method: PaymentMethodDialogType) => void;
    getSplittedPaymentMethods: () => any[];
    showPaymentProcessingDialog: ({ paymentStatus }: PaymentProcessingType) => void;
    hidePaymentProcessingDialog: () => void;
    handleNonPaymentOrderPlacement: (method: NonPaymentMethodType) => Promise<void>;
    handleUnapplicablePaymentMethod: (
      mode: PaymentModeType,
      reason: UnApplicablePaymentReasonType[],
    ) => Promise<void>;
    handleCancelPayment: () => void;
    setPaymentState: (state: Partial<PaymentState>) => void;
  };
}

function reducer(state: PaymentState, action: Action): PaymentState {
  switch (action.type) {
    case ActionType.SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: action.payload,
      };
    }
    case ActionType.SET_PAYMENT_METHOD_DIALOG: {
      return {
        ...state,
        openPaymentMethodDialog: action.payload,
      };
    }
    case ActionType.SET_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
  return state;
}

const PaymentInitialState: PaymentState = {
  paymentMethods: [],
  openPaymentMethodDialog: "NONE",
  isProcessingDialogVisible: false,
  paymentStatus: "DRAFT",
  isPolling: false,
  splittedPaymentMethods: [],
  isCancellable: true,
};

export const PaymentProvider: React.FC<React.PropsWithChildren<{ initialState?: PaymentState }>> = ({
  initialState = PaymentInitialState,
  children,
}) => {
  const [reducerState, dispatch] = useReducer(reducer, initialState);
  const {
    state: {
      hasDefaultShippingHandleSelected,
      checkoutId,
      billing,
      isC2P,
      originUrl,
      checkoutToken,
      initialCheckoutStep,
    },
    actions: {
      setCheckoutModal,
      setOOSItems,
      setCoupons,
      updateCheckoutFromPayments,
      setCheckoutExpired,
      setActiveComponent,
    },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: { user },
  } = useUserContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { setIsLoading } = useLoader();
  const { t } = useLocale();

  const setPaymentMethods = useCallback((methods: any) => {
    dispatch({
      type: ActionType.SET_PAYMENT_METHODS,
      payload: methods,
    });
  }, []);

  const setPaymentMethodDialog = useCallback((dialog: PaymentMethodDialogType = "NONE") => {
    dispatch({
      type: ActionType.SET_PAYMENT_METHOD_DIALOG,
      payload: dialog,
    });
  }, []);

  const setPaymentState = useCallback((state: Partial<PaymentState>) => {
    dispatch({
      type: ActionType.SET_STATE,
      payload: state,
    });
  }, []);

  const showProcessingDialog = useCallback(
    ({ paymentStatus }: PaymentProcessingType) => {
      setPaymentState({
        isProcessingDialogVisible: true,
        paymentStatus: paymentStatus,
      });
    },
    [setPaymentState],
  );

  const hideProcessingDialog = useCallback(() => {
    setPaymentState({
      isProcessingDialogVisible: false,
      paymentStatus: "DRAFT",
    });
  }, [setPaymentState]);

  const handlePaymentSucess = useCallback(() => {
    setPaymentState({
      paymentStatus: "PROCESSING",
      isPolling: true,
      isProcessingDialogVisible: true,
    });
  }, [setPaymentState]);

  const handlePaymentFailure = useCallback(async () => {
    setPaymentState({
      isPolling: false,
    });
    hideProcessingDialog();
    errorToast(t("payment_failed_retry"));
  }, [hideProcessingDialog, setPaymentState, t]);

  const handleCancelPayment = useCallback(() => {
    hideProcessingDialog();
    setPaymentState({
      isPolling: false,
    });
  }, [hideProcessingDialog, setPaymentState]);

  const handlePaymentMethodSelected = useCallback(
    async (method: PaymentModeType, context?: "SPLIT_COD") => {
      if (!method) return;

      const hasDefaultHandle = hasDefaultShippingHandleSelected;

      if (!Boolean(hasDefaultHandle)) {
        setCheckoutModal("SHIPPING_HANDLES");
        return;
      }

      const methodData = reducerState.paymentMethods?.find((e: any) => e.mode === method);
      if (!methodData || isEmptyObj(methodData)) return;

      //TODO handle group
      if (!methodData.is_mode) return;

      if (method !== "UPI_INTENT" && method !== "UPI_COLLECT") {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
          eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
          eventType: "click",
          metaData: {
            methodType: method,
          },
        });
      }

      const specialPaymentMethods = ["COD", "OFFLINE_PAYMENT"];
      if (specialPaymentMethods.includes(method)) {
        setPaymentMethodDialog(method);
        return;
      }

      //Handling Split COD Payment mode
      if (method === "SPLIT_COD") {
        try {
          setIsLoading(true);
          const paymentResponse = await getPaymentMethodAction(
            method,
            checkoutId,
            undefined,
            undefined,
            setCheckoutExpired,
          );
          paymentResponse?.modes?.forEach((method: any) => (method.context = "SPLIT_COD"));

          setPaymentState({
            splittedPaymentMethods: paymentResponse?.modes?.filter((method: any) => method.is_available),
            openPaymentMethodDialog: "SPLIT_COD",
          });
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
        return;
      }

      //Handling other payment methods
      try {
        setIsLoading(true);
        const paymentResponse = await getPaymentMethodAction(
          method,
          checkoutId,
          context,
          undefined,
          setCheckoutExpired,
        );
        if (paymentResponse?.implementation === "DEFAULT") {
          await loadPgSdk(
            (paymentResponse as any)?.pg,
            (paymentResponse as any)?.implementation,
            (paymentResponse as any)?.pg_key,
            (paymentResponse as any)?.gateway_version,
            (paymentResponse as any)?.pg_account_id,
          );
          const hasRedirection = paymentResponse?.next_steps?.pg_action?.action === "redirect" ? true : false;
          const redirectUrl = hasRedirection ? paymentResponse?.ui_callback_url : "";
          if ((paymentResponse.pg as PaymentGateways) === "CF") {
            setPaymentMethodDialog("CF");
            await sleep(50);
          }

          setIsLoading(false);

          await initializePayment({
            amount: billing.prepaid_total ?? 0,
            pgOrderId: paymentResponse.pg_order_reference,
            pg: paymentResponse.pg as PaymentGateways,
            pgImpl: "DEFAULT",
            pgRedirectUrl: paymentResponse.next_steps?.pg_action?.action_url,
            pgAction: paymentResponse.next_steps?.pg_action?.action,
            paymentMode: paymentResponse.mode as PaymentModeType,
            pgAccountId: paymentResponse.pg_account_id,
            onSuccessHandler: handlePaymentSucess,
            onFailureHandler: handlePaymentFailure,
            onCancelHandler: handleCancelPayment,
            checkoutId,
            merchantId: merchant?.merchantId,
            merchantName: merchant?.displayName,
            brandLogoUrl: merchant?.logoUrl,
            brandThemeColor: merchant?.colorPallet?.primaryColor,
            userName: user?.name ?? "",
            userPhone: user?.phone ?? "",
            userEmail: user?.email ?? "",
            userId: paymentResponse?.pg_customer_id ?? "",
            redirectUrl: redirectUrl,
            floOrderReference: paymentResponse?.flo_order_reference,
            pgVersion: paymentResponse?.gateway_version,
            pgData: paymentResponse?.pg_data,
            shouldRenderIframe: paymentResponse?.iframe,
          });
          return;
        }
        setIsLoading(false);
        //handle custom case
        setPaymentMethodDialog(method);
      } catch (e: any) {
        console.error(e);
        setIsLoading(false);
        const errorCode = e?.response?.data?.error;
        if (errorCode === "OUT_OF_STOCK") {
          const oosItems = e?.response?.data?.error_info?.metadata?.error_response?.metadata?.items;
          setOOSItems(getItems(oosItems, true) ?? []);
          setCheckoutModal("OOS");
          return;
        }
      }

      return {};
    },
    [
      billing.prepaid_total,
      checkoutId,
      handlePaymentFailure,
      handlePaymentSucess,
      hasDefaultShippingHandleSelected,
      merchant?.colorPallet?.primaryColor,
      merchant?.displayName,
      merchant?.logoUrl,
      merchant?.merchantId,
      reducerState.paymentMethods,
      sendAnalyticsEvent,
      setCheckoutModal,
      setIsLoading,
      setOOSItems,
      setPaymentMethodDialog,
      setPaymentState,
      user?.email,
      user?.name,
      user?.phone,
    ],
  );

  const getSplittedPaymentMethods = useCallback(
    () => reducerState.splittedPaymentMethods,
    [reducerState.splittedPaymentMethods],
  );

  const handleNonPaymentOrderPlacement = useCallback(
    async (method: NonPaymentMethodType) => {
      let nonPaymentMethod = "free-online";

      if (method === "COD") {
        nonPaymentMethod = "cod";
        setPaymentMethodDialog("NONE");
      }
      if (method === "OFFLINE_PAYMENT") {
        nonPaymentMethod = "offline-payment";
        setPaymentMethodDialog("NONE");
      }

      try {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_PAYMENT_MODE_SELECTED,
          eventType: "click",
          metaData: {
            modeType: method,
            methodType: method,
          },
        });
        setPaymentState({
          paymentStatus: "PROCESSING",
          isProcessingDialogVisible: true,
          // since this method handles only non-payment methods, we can set isCancellable to true
          // but I want to explicit here to make sure it's only handled for non-payment methods - removes any confusion
          isCancellable: !["cod", "offline-payment", "free-online"].includes(nonPaymentMethod),
        });
        const response = await postRequest(`/checkout/v1/checkout/${checkoutId}/${nonPaymentMethod}`, {});
        const status = response?.status;
        const orderData = response?.order?.platform_order?.data;
        const redirectUrl =
          Boolean(orderData?.order_status_url) && !merchant?.forceCustomThankYouPage
            ? orderData?.order_status_url
            : addHttpIfMissing(`${originUrl}/pages/order-status?tokenId=${checkoutToken}`);

        if (redirectUrl && status === "COMPLETED") {
          sendAnalyticsEvent({
            eventName: analyticsEvents.FLO_PAYMENT_COMPLETED,
            eventFor: [analyticsTypes.SF_ANALYTICS, ...(!isC2P ? [analyticsTypes.FACEBOOK_PIXEL] : [])],
            eventType: "click",
            metaData: {
              orderAmount: Number(response?.order?.amount),
            },
          });
          handlePaymentSuccessRedirection(redirectUrl);
          return;
        }
        if (status === "ORDER_FAILED") {
          setPaymentState({
            paymentStatus: "ORDER_FAILED",
          });
          return;
        }
        setPaymentState({
          isProcessingDialogVisible: false,
        });
      } catch (err: any) {
        console.error(err);
        setPaymentState({
          isProcessingDialogVisible: false,
        });
        const errorCode = err?.response?.data?.error;
        if (errorCode === "OUT_OF_STOCK") {
          const oosItems = err?.response?.data?.error_info?.metadata?.error_response?.metadata?.items;
          setOOSItems(getItems(oosItems, true) ?? []);
          setCheckoutModal("OOS");
          return;
        }
        errorToast(t("order_place_failed"));
      }
    },
    [
      checkoutId,
      isC2P,
      sendAnalyticsEvent,
      setCheckoutModal,
      setOOSItems,
      setPaymentMethodDialog,
      setPaymentState,
      t,
    ],
  );

  const handleUnapplicablePaymentMethod = useCallback(
    async (mode: PaymentModeType, reason: UnApplicablePaymentReasonType[]) => {
      if (!mode || !Boolean(reason?.length)) return;
      try {
        setIsLoading(true);
        const response = await putRequest(
          `/checkout/v1/checkout/${checkoutId}/payment-modes/${mode}/enable`,
          {},
        );

        const discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
        setCoupons(discountResponse);
        updateCheckoutFromPayments(response);

        // incase of one page or multiple page mutate the payments as we have
        // just enabled it and don't wait for shipping handles
        // in the updateCheckoutFromPayments
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        if (
          initialCheckoutStep === "PAYMENTS" &&
          Boolean(response?.metadata?.show_shipping_handle_selector)
        ) {
          setActiveComponent("SHIPPING_SECTION");
        }

        infoToast(t("order_updated_toaster"));
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [checkoutId, setCoupons, setIsLoading, t, updateCheckoutFromPayments],
  );

  const pollPaymentStatus = useCallback(async () => {
    try {
      const paymentStatus: any = await getPaymentStatus(checkoutId);
      if (paymentStatus?.status === "PAYMENT_COMPLETED") {
        setPaymentState({
          paymentStatus: "PLACING_ORDER",
        });
        triggerSuccessEvent();
        return;
      }
      if (paymentStatus?.status === "COMPLETED") {
        setPaymentState({
          isPolling: false,
        });
        const redirectUrl =
          Boolean(paymentStatus?.order?.platform_order?.data?.order_status_url) &&
          !merchant?.forceCustomThankYouPage
            ? paymentStatus?.order?.platform_order?.data?.order_status_url
            : addHttpIfMissing(`${originUrl}/pages/order-status?tokenId=${checkoutToken}`);

        triggerSuccessEvent();
        if (typeof window !== "undefined" && window.localStorage.getItem("payment_success_flag")) {
          localStorage.removeItem("payment_success_flag");
        }

        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_PAYMENT_COMPLETED,
          eventFor: [analyticsTypes.SF_ANALYTICS, ...(!isC2P ? [analyticsTypes.FACEBOOK_PIXEL] : [])],
          eventType: "click",
          metaData: {
            orderAmount: Number(paymentStatus?.order?.amount),
          },
        });

        if (redirectUrl) {
          handlePaymentSuccessRedirection(redirectUrl);
        }
        return;
      }

      if (paymentStatus?.status === "ORDER_FAILED") {
        setPaymentState({
          isPolling: false,
          paymentStatus: "ORDER_FAILED",
        });
        return;
      }
    } catch (e) {
      console.error(e);
    }
  }, [checkoutId, isC2P, sendAnalyticsEvent, setPaymentState]);

  useInterval(pollPaymentStatus, reducerState.isPolling ? 3000 : null);

  const actions = useMemo(
    () => ({
      setPaymentMethods,
      setPaymentMethodDialog,
      setPaymentState,
      handlePaymentMethodSelected,
      getSplittedPaymentMethods,
      showPaymentProcessingDialog: showProcessingDialog,
      hidePaymentProcessingDialog: hideProcessingDialog,
      handleNonPaymentOrderPlacement,
      handleUnapplicablePaymentMethod,
      handleCancelPayment,
    }),
    [
      getSplittedPaymentMethods,
      handleCancelPayment,
      handleNonPaymentOrderPlacement,
      handlePaymentMethodSelected,
      handleUnapplicablePaymentMethod,
      hideProcessingDialog,
      setPaymentMethodDialog,
      setPaymentMethods,
      setPaymentState,
      showProcessingDialog,
    ],
  );

  return (
    <Context.Provider
      value={{
        state: reducerState,
        actions,
      }}>
      {children}
    </Context.Provider>
  );
};

export function usePaymentContext() {
  if (!Boolean(Context)) throw new Error("usePaymentContext must be used within a PaymentProvider");
  const PaymentContext = useContext(Context);
  return PaymentContext as PaymentContext;
}
