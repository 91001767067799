import { useEffect, useState } from "react";
import { useAuthContext } from "lib/contexts/AuthProvider";
import {
  publishPostMessage,
  inIframe,
  isThirdPartyCookieEnabled,
  createFloSessionCookie,
  exitCheckout,
} from "lib/utils/helpers";
import { eventTypes } from "lib/utils/constants";
import { refreshExpiredIdToken } from "lib/utils/auth";
import { Toaster } from "react-hot-toast";
import Skeleton from "components/common/loaders/Skeleton";
import CheckoutExitPopup from "components/common/popups/CheckoutExitPopup";
import { useSearchParams } from "react-router-dom";
import AppRouter from "components/layout/AppRouter";
import { useLoader } from "lib/contexts/LoaderContext";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { initialiseClarity } from "lib/third-party/clarity";

function App() {
  const {
    actions: { setLoggedIn },
  } = useAuthContext();
  const {
    state: { redirectUrl },
  } = useCheckoutContext();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page")?.toLowerCase();

  const [isValidatingAuth, setIsValidatingAuth] = useState<boolean>(true);
  const [openCheckoutExitPopup, setOpenCheckoutExitPopup] = useState<boolean>(false);

  const { isLoading } = useLoader();

  useEffect(() => {
    if (
      inIframe() &&
      !isThirdPartyCookieEnabled() &&
      page !== "cart" &&
      page !== "login-status-check" &&
      page !== "ghost-modal" &&
      page !== "login-sso" &&
      page !== "marketing-popup"
    ) {
      publishPostMessage(eventTypes.PARENT_REDIRECT, {
        redirectUrl: document.location.href,
        clearCart: false,
        isSso: page === "login-sso",
      });
      return;
    }

    initialiseClarity();
    revalidateRefreshToken();
    createFloSessionCookie();
    // initializeHotjar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const revalidateRefreshToken = async () => {
    const presignedRefreshtToken = searchParams.get("auth");
    const isLoggedIn: boolean = await refreshExpiredIdToken(presignedRefreshtToken ?? undefined);
    setLoggedIn(isLoggedIn);
    setIsValidatingAuth(false);
  };

  // const onBackButtonEvent = useCallback(
  //   (e: any) => {
  //     e.preventDefault();
  //     if (checkoutModal !== "NONE") return;
  //     if (checkoutView === "PAYMENTS") {
  //       setCheckoutViewCtx("ADDRESS_LIST");
  //       return;
  //     }
  //     if (checkoutView !== "ADDRESS_LIST") return;
  //     setOpenCheckoutExitPopup(true);
  //   },
  //   [checkoutView, checkoutModal],
  // );

  // useBackButton(onBackButtonEvent);

  const onCloseCheckoutExitPopup = () => {
    window.history.pushState(null, "", null);
    setOpenCheckoutExitPopup(false);
    setTimeout(() => {
      document?.documentElement?.style?.removeProperty("overflow");
    }, 500);
  };

  const handleBrowserBackEvent = async () => {
    setOpenCheckoutExitPopup(false);
    exitCheckout(redirectUrl);
  };

  return isValidatingAuth && page !== "marketing-popup" ? (
    <Skeleton />
  ) : (
    <>
      <AppRouter />

      <Toaster />

      {Boolean(isLoading) && <OverlaySpinner />}

      <CheckoutExitPopup
        closeCheckout={handleBrowserBackEvent}
        isOpen={openCheckoutExitPopup}
        closePopup={onCloseCheckoutExitPopup}
      />
    </>
  );
}

export default App;
