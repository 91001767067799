import React from "react";
import Price from "components/common/Price";
import { AppliedCouponType, useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { Edit, Edit2, XCircle } from "react-feather";
import { useLocale } from "lib/hooks/useLocale";

interface AppliedItemProps {
  coupon: AppliedCouponType;
  handleDeleteItem: (value: string) => void;
}

const AppliedItem: React.FC<AppliedItemProps> = ({ coupon, handleDeleteItem }) => {
  const { t } = useLocale();
  const { actions: { setCheckoutModal } } = useCheckoutContext();
  return (
    <div className="flex w-full flex-col py-2.5 pr-4 pl-4">
      <div className="border-box flex w-full flex-row">
        <div className="flex w-full flex-row items-center justify-between overflow-hidden pr-2">
          <h3 className="flex w-full items-center space-x-1 truncate pr-2 text-sm font-normal text-coal-light">
            <p className="text-md truncate font-medium uppercase text-coal-dark">{`${
              Boolean(coupon?.title) ? coupon?.title : coupon?.code
            }`}</p>
          </h3>
          {!Boolean(coupon?.isProductSelector) ? (
            <p className="inline-flex w-1/2 justify-end overflow-hidden text-sm font-medium text-yay-dark">
              <Price total={coupon?.discountValue ?? ""} isDiscounted={true} />
            </p>
          ) : (
            <p className="inline-flex w-1/2 space-x-1 justify-end overflow-hidden text-sm font-medium text-yay-dark underline cursor-pointer" onClick={() => setCheckoutModal("DISCOUNTED_PRODUCT_SELECTOR")}>
              {t("view")}
            </p>
          )}
        </div>

        {/* Disable delete coupon button if it is auto applied */}
        {Boolean(coupon?.code) && Boolean(coupon?.isRemovable) && !Boolean(coupon?.isProductSelector) && (
          <button
            className="flex h-4 w-4 items-center justify-center"
            onClick={() => handleDeleteItem(coupon?.code)}>
            <XCircle className="h-4 w-4 text-coal-dark" strokeWidth={2.5} />
          </button>
        )}
      </div>
    </div>
  );
};

export default AppliedItem;
