import { UserHistoryType, UserType } from "lib/types/user";
import { getAddressById, formatAllAddresses, hasAddressType } from "lib/utils/address";
import { constants } from "./constants";
import Cookies from "js-cookie";
import { getRequest } from "lib/core/apiClient";
import { setCheckoutUserType } from "./checkout";
/**
 * Takes accountResponse and checkoutResponse data. Parses and converts them to user type data.
 *
 * @param  {object} accountResponse       Account Attributes data response
 * @param  {object} checkoutResponse      Checkout data response. Used for default addresses in this context
 *
 * @return {object} userData   Parsed User data in the type of UserType
 */
export const parseUserData = (accountResponse: any, checkoutResponse: any) => {
  const areAdressesSame =
    checkoutResponse?.addresses?.shipping_address_id === checkoutResponse?.addresses?.billing_address_id;

  let shippingAddress;
  let billingAddress;

  shippingAddress = getAddressById(
    checkoutResponse?.addresses?.shipping_address_id,
    accountResponse?.addresses,
  );

  if (!areAdressesSame) {
    billingAddress = getAddressById(
      checkoutResponse?.addresses?.billing_address_id,
      accountResponse?.addresses,
    );
  }

  const userData: UserType = {
    uid: accountResponse?.uid,
    email: accountResponse?.account_attributes?.email,
    phone: accountResponse?.phone,
    name: accountResponse?.account_attributes?.attributes?.name,
    addresses: formatAllAddresses(accountResponse?.addresses, shippingAddress?.id ?? ""),
    default_billing_address: areAdressesSame ? shippingAddress : billingAddress,
    default_shipping_address: shippingAddress,
  };

  return userData;
};

export const getUserOrderHistory = (merchantId: string): UserHistoryType => {
  const orderHistory = localStorage.getItem(constants.ORDER_HISTORY_COOKIE_CLIENT);
  if (!orderHistory || !Boolean(orderHistory)) {
    return "NA";
  }
  const orderHistoryObj = JSON.parse(orderHistory)[merchantId];
  if (!orderHistoryObj || !Boolean(orderHistoryObj)) {
    return "NA";
  }
  if (orderHistoryObj?.orders_count > 0) {
    return "OLD";
  }
  return "NEW";
};

export const setUserOrderHistory = async (accountId: string, merchantId: string, checkoutId: string) => {
  const userOrderHistory = getUserOrderHistory(merchantId);
  if (userOrderHistory === "OLD") {
    setCheckoutUserType(checkoutId, userOrderHistory);
    return;
  }
  try {
    const orderHistoryResponse = await getRequest(
      `/oms/merchant/${merchantId}/customer/${accountId}/order-context?status=COMPLETED`,
      "KRATOS_PRIVATE",
    );
    if (orderHistoryResponse?.status === 401 || orderHistoryResponse?.status === 403) {
      throw new Error("unauthorized");
    }
    const orderHistory = localStorage.getItem(constants.ORDER_HISTORY_COOKIE_CLIENT);
    const orderHistoryObj = orderHistory ? JSON.parse(orderHistory) : {};
    orderHistoryObj[merchantId] = orderHistoryResponse;
    localStorage.setItem(constants.ORDER_HISTORY_COOKIE_CLIENT, JSON.stringify(orderHistoryObj));
    setCheckoutUserType(checkoutId, Boolean(orderHistoryResponse?.orders_count) ? "OLD" : "NEW");
  } catch (error) {
    console.error(error);
  }
};
