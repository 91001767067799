import { AddressCardFields } from "lib/types/address";
import { Value as PhoneInputValueType } from "react-phone-number-input";

export type UserType = {
  uid: string;
  email?: string;
  phone: PhoneInputValueType;
  name?: string;
  default_billing_address?: AddressCardFields | undefined;
  default_shipping_address?: AddressCardFields | undefined;
  addresses: AddressCardFields[] | undefined;
  hasHomeAddr?: boolean;
  hasWorkAddr?: boolean;
};

export type UserLoginType = "guest_login" | "return_user_login" | "cookie_login" | "na";

export type OTPChannel = "SMS" | "WHATSAPP" | "EMAIL";

export type OTPVerificationDetails = {
  identifier: string;
  merchantId: string;
  userId?: string;
  skipVerification?: boolean;
  forceVerification?: boolean;
  domain?: string;
};

export enum AuthSource {
  "SHOPFLO" = "SHOPFLO",
  "CUSTOM" = "CUSTOM",
}

export type UserHistoryType = "NEW" | "OLD" | "NA"
