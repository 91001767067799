import React from "react";
import { t } from "i18next";
import visaLogo from "assests/images/visa-logo.png";
import amexLogo from "assests/images/amex.png";
import dinersLogo from "assests/images/diners-club.png";
import mastercardLogo from "assests/images/mastercard.png";
import rupayLogo from "assests/images/rupay-logo.png";
import { detectDeviceOS, isInstagramBrowser, handlePaymentSuccessRedirection } from "lib/utils/helpers";
import {
  PaymentGateways,
  PaymentGatewayImpl,
  MethodType,
  PaymentModeType,
  InitializePayment,
  RzpBaseOptions,
  RzpPaymentModes,
  CfDefaultPaymentModes,
  UnApplicablePaymentReasonType,
  PAYUIframeData,
} from "lib/types/payments";
import {
  loadRZPCustomSdk,
  loadRZPDefaultSDK,
  initializeRzpCustomPayment,
  initializeRzpDefaultPayment,
} from "lib/third-party/razorpay";
import { postRequest } from "lib/core/apiClient";
import {
  loadCfCustomSDK,
  loadCfDefaultSDK,
  initializeCfCustomPayment,
  initializeCfDefaultPayment,
} from "lib/third-party/cashfree";
import { isMobile } from "lib/utils/helpers";
import { loadPaytmDefaultSDK, initializePaytmDefaultPayment } from "lib/third-party/paytm";
import { initializeEasebuzzDefaultPayment, loadEasebuzzDefaultSdk } from "lib/third-party/easebuzz";
import { initializePayuDefaultPayment, loadPayUDefaultSdk } from "lib/third-party/payu";
import { Simpl } from "assests/icons/Simpl";
import { currencyFormatter } from "lib/utils/formatters";

const Card = React.lazy(() => import("assests/icons/Card"));
const Wallet = React.lazy(() => import("assests/icons/Wallet"));
const COD = React.lazy(() => import("assests/icons/COD"));
const Netbanking = React.lazy(() => import("assests/icons/Netbanking"));
const Paylater = React.lazy(() => import("assests/icons/Paylater"));
const EMI = React.lazy(() => import("assests/icons/EMI"));
const UpiIcons = React.lazy(() => import("assests/icons/UPIIcons"));

export const getNetworkLogo = (network: string) => {
  switch (network.toLowerCase()) {
    case "Visa".toLowerCase():
      return visaLogo;
    case "RuPay".toLowerCase():
      return rupayLogo;
    case "MasterCard".toLowerCase():
      return mastercardLogo;
    case "amex".toLowerCase():
      return amexLogo;
    case "American Express".toLowerCase():
      return amexLogo;
    case "Diners Club".toLowerCase():
      return dinersLogo;
    case "diners".toLowerCase():
      return dinersLogo;
  }
};

export const supportedCardNetworks = (network: string) => {
  return ["visa", "rupay", "mastercard", "amex", "american express"].includes(network.toLowerCase())
    ? network
    : "";
};

export const getUpiAppLink = (upiApp: string, intentLink: string) => {
  switch (upiApp) {
    case "phonepe":
      return intentLink.replace("upi", "phonepe");
    case "gpay":
      return intentLink.replace("upi://", "tez://upi/");
    case "paytm":
      return detectDeviceOS() === "iOS"
        ? intentLink.replace("upi://", "paytm://upi/")
        : intentLink.replace("upi", "paytmmp");
    default:
      return intentLink;
  }
};

export const loadPgSdk = async (
  pg: PaymentGateways,
  pgImpl: PaymentGatewayImpl,
  pgKey: string,
  pgVersion: string,
  pgAccountId?: string,
  isRzpPref?: boolean,
) => {
  let sdkResponse;
  pgKey = pgKey ?? import.meta.env.VITE_RZRPAY_API_KEY;
  switch (pg) {
    case "RP": {
      sdkResponse = await (pgImpl === "CUSTOM"
        ? loadRZPCustomSdk(pgKey, pgAccountId, isRzpPref)
        : loadRZPDefaultSDK());
      break;
    }
    case "CF": {
      sdkResponse = await (pgImpl === "CUSTOM" ? loadCfCustomSDK() : loadCfDefaultSDK(pgVersion));
      break;
    }
    case "PAYTM": {
      sdkResponse = await loadPaytmDefaultSDK(pgKey);
      break;
    }
    case "EASEBUZZ": {
      sdkResponse = await loadEasebuzzDefaultSdk(pgKey);
      break;
    }
    case "PAYU": {
      sdkResponse = await loadPayUDefaultSdk();
      break;
    }
  }
  return sdkResponse;
};

export const getDialogHeader = (selectedPayment: PaymentModeType) => {
  if (!Boolean(selectedPayment)) {
    return;
  }
  const header = paymentMethodsConfig[selectedPayment]?.header;
  return header;
};

export const paymentMethodsConfig: MethodType = {
  UPI_COLLECT: {
    logo: UpiIcons,
    header: t("upi_payment_header"),
    subheader: isMobile() ? t("upi_payment_subheader_apps_collect") : t("upi_payment_subheader"),
    mappers: {
      RP: "upi",
      CF: "upi",
    },
  },
  UPI: {
    logo: UpiIcons,
    header: t("upi_payment_header"),
    subheader: t("upi_payment_subheader"),
    mappers: {
      RP: "upi",
      CF: "upi",
    },
  },
  UPI_INTENT: {
    logo: UpiIcons,
    header: t("upi_payment_header"),
    subheader: t("upi_payment_subheader"),
    mappers: {
      RP: "upi",
      CF: "upi",
    },
  },
  CARD: {
    logo: Card,
    header: t("card_payment_header"),
    subheader: t("card_payment_subheader"),
    mappers: {
      RP: "card",
      CF: "card",
    },
  },
  COD: {
    logo: COD,
    header: t("cod_payment_header"),
    subheader: t("cod_payment_subheader"),
  },
  WALLET: {
    logo: Wallet,
    header: t("wallets_payment_header"),
    subheader: t("wallets_payment_subheader"),
    mappers: {
      RP: "wallet",
      CF: "app",
    },
  },
  NETBANKING: {
    logo: Netbanking,
    header: t("netbanking_payment_header"),
    subheader: t("netbanking_payment_subheader"),
    mappers: {
      RP: "netbanking",
      CF: "netbanking",
    },
  },
  EMI: {
    logo: EMI,
    header: t("emi_payment_header"),
    subheader: t("emi_payment_subheader"),
    mappers: {
      RP: "emi",
      CF: "cardlessemi",
    },
  },
  PAY_LATER: {
    logo: Paylater,
    header: t("pay_later_payment_header"),
    subheader: t("pay_later_payment_subheader"),
    mappers: {
      RP: "paylater",
      CF: "paylater",
    },
  },
  SNPM: {
    logo: EMI,
    header: t("snapmint_payment_header"),
    subheader: t("snapmint_payment_subheader"),
  },
  SIMPL: {
    logo: Simpl,
    header: t("simpl_payment_header"),
    subheader: t("simpl_payment_subheader"),
  },
  SIMPL_PL: {
    logo: Simpl,
    header: '',
    subheader: '',
  },
  BHARATX: {
    logo: Card,
    header: t("bharatx_payment_header"),
    subheader: t("snapmint_payment_subheader"),
  },
  CRED: {
    logo: Card,
    header: t("cred_payment_header"),
    subheader: t("cred_payment_subheader"),
    mappers: {
      RP: "cred",
    },
  },
  CASHFREE: {
    logo: Card,
    header: t("online_payment_header"),
    subheader: t("online_payment_subheader"),
  },
  GC: {
    logo: Card,
    header: t("gift_card_payment_header"),
    subheader: t("gift_card_payment_subheader"),
  },
  PL: {
    logo: Card,
    header: t("payment_link_header"),
    subheader: t("payment_link_subheader"),
  },
  LOYALTY: {
    logo: Card,
    header: t("loyalty_payment_header"),
    subheader: t("loyalty_payment_subheader"),
  },
  PAYPAL: {
    logo: Card,
    header: t("paypal"),
    subheader: t("paypal_payment_subheader"),
    mappers: {
      RP: "paypal",
    },
  },
  OFFLINE_PAYMENT: {
    logo: Card,
    header: t("online_payment_header"),
    subheader: t("online_payment_subheader"),
  },
  SPLIT_COD: {
    logo: COD,
    header: t("online_payment_header"),
    subheader: t("online_payment_subheader"),
  },
};

export const initializePayment = async ({
  amount,
  pgOrderId,
  pg,
  pgImpl,
  pgRedirectUrl,
  pgAction,
  pgAccountId,
  paymentMode,
  userId,
  userName,
  userPhone,
  userEmail,
  checkoutId,
  merchantId,
  merchantName,
  currency = "INR",
  brandLogoUrl,
  brandThemeColor = "#5a31f4",
  onSuccessHandler,
  onFailureHandler,
  onCancelHandler,  
  redirectUrl,
  methodPayload,
  floOrderReference,
  pgVersion,
  pgData,
  shouldRenderIframe,
}: InitializePayment) => {
  if (!Boolean(pg)) return;
  try {
    if (Boolean(pgAction === "redirect") && Boolean(pgRedirectUrl)) {
      handlePaymentSuccessRedirection(pgRedirectUrl ?? "", false);
      return;
    }

    switch (pg) {
      case "RP": {
        const baseOptions: RzpBaseOptions = {
          amount,
          rzpId: pgOrderId,
          merchantId,
          merchantName,
          method: findRzpPaymentMethodMapper(paymentMode),
          checkoutId,
          userPhone,
          userEmail,
          redirectUrl,
          ...(Boolean(pgAccountId) && { accountId: pgAccountId }),
        };
        if (pgImpl === "CUSTOM") {
          const pgCustomerId = paymentMode === "CARD" || paymentMode === "SAVED_MODES" ? userId : "";
          const response = await initializeRzpCustomPayment({
            ...baseOptions,
            userId: pgCustomerId,
            merchantName: merchantName,
            onFailureHandler: onFailureHandler,
            methodPayload,
          });
          return response;
        }

        const reponse = await initializeRzpDefaultPayment({
          ...baseOptions,
          brandLogoUrl,
          brandThemeColor,
          userName,
          onSuccessHandler,
        });
        return reponse;
      }
      case "SNPM": {
        (document as any).location.href = pgRedirectUrl;
        return;
      }
      case "CF": {
        if (pgImpl === "CUSTOM") {
          const cfResponse = await initializeCfCustomPayment({
            order_token: pgOrderId,
            method: findCfPaymentMethodMapper(paymentMode),
            methodPayload: { ...methodPayload, ...{ phoneNumber: userPhone } },
          });
          const paymentRedirectUrl = cfResponse?.data?.url;
          if (Boolean(paymentRedirectUrl)) {
            handlePaymentSuccessRedirection(paymentRedirectUrl, false);
          }
          return;
        }
        if (pgAction === "redirect" && Boolean(pgRedirectUrl)) {
          // (document as any).location.href = pgRedirectUrl;
          handlePaymentSuccessRedirection(pgRedirectUrl ?? "", false);
          return;
        }
        initializeCfDefaultPayment({
          orderToken: pgOrderId,
          paymentMode: findCfPaymentMethodMapper(paymentMode),
          brandThemeColor: brandThemeColor,
          onSuccessHandler: onSuccessHandler,
          onFailureHandler: onFailureHandler,
          pgVersion: pgVersion,
        });
        break;
      }
      case "PAYU": {
        if (shouldRenderIframe && pgData) {
          initializePayuDefaultPayment(
            {
              data: pgData,
              onSuccessHandler: onSuccessHandler,
              onFailureHandler: onFailureHandler,
              onCancelHandler: onCancelHandler,
            }
          );
          return;
        }
        if (!pgRedirectUrl) return;
        handlePaymentSuccessRedirection(pgRedirectUrl ?? "", false);
        return;
      }
      case "PHONEPE": {
        if (!pgRedirectUrl) return;
        handlePaymentSuccessRedirection(pgRedirectUrl ?? "", false);
        return;
      }
      case "PAYTM": {
        initializePaytmDefaultPayment({
          orderId: floOrderReference ?? "",
          token: pgOrderId,
          amount: amount,
        });
        return;
      }
      case "BHARATX": {
        handlePaymentSuccessRedirection(pgRedirectUrl ?? "", false);
        return;
      }
      case "EASEBUZZ": {
        initializeEasebuzzDefaultPayment({
          accessKey: pgOrderId,
          onSuccessHandler,
          onFailureHandler,
        });
        return;
      }
      case "SIMPL": {
        handlePaymentSuccessRedirection(pgRedirectUrl ?? "", false);
        return
      }
    }
  } catch (e: any) {
    throw new Error(e);
  }
};

export const findRzpPaymentMethodMapper = (mode: PaymentModeType): RzpPaymentModes => {
  const mappers = paymentMethodsConfig[mode]?.mappers;
  // @ts-ignore
  return mappers.RP;
};

export const findCfPaymentMethodMapper = (mode: PaymentModeType): CfDefaultPaymentModes => {
  const mappers = paymentMethodsConfig[mode]?.mappers;
  // @ts-ignore
  return mappers.CF;
};

export const paymentModeNextStep = (mode: PaymentModeType): "redirect" | "new_tab" => {
  if (mode === "PL") return "redirect";
  if (mode === "CASHFREE") return "redirect";
  return (window as any).ReactNativeWebView || isInstagramBrowser() || mode === "SNPM" || mode === "SIMPL"
    ? "redirect"
    : "new_tab";
};

export const handleSavedModes = async (checkoutId: string) => {
  if (isInstagramBrowser()) return "";
  const payload = {
    payment_mode: "SAVED_MODES",
    action: paymentModeNextStep("SAVED_MODES"),
  };

  try {
    const response = await postRequest(`/checkout/v2/checkout/${checkoutId}/payments/initiate`, payload);
    await loadPgSdk(
      (response as any)?.pg,
      (response as any)?.implementation,
      (response as any)?.pg_key,
      (response as any)?.gateway_version,
    );
    const savedModePgOrderId = response?.pg_order_reference;
    const customerPgId = response?.pg_customer_id;
    return { savedModePgOrderId, customerPgId };
  } catch (e) {
    return "";
  }
};

export const isDefaultImplementationMode = (mode: PaymentModeType): boolean => {
  if (mode === "CASHFREE") return true;
  if (isInstagramBrowser() || mode === "PAY_LATER" || mode === "EMI") return true;
  return false;
};

export const filterPaymentMethods = (paymentMethods: any[], type: "AVAILABLE" | "UNAVAILABLE"): any[] => {
  return paymentMethods.filter((method) => {
    return type === "AVAILABLE" ? method.is_available : !method.is_available;
  });
};

export const getUnApplicablePaymentReason = (
  reasons: UnApplicablePaymentReasonType[],
  modeTitle: string,
): string => {
  if (!reasons || reasons.length === 0) return t("payment_mode_not_available");

  const hasDiscountsBlocker = reasons.find((e) => e.type === "INVALID_DISCOUNTS");
  if (hasDiscountsBlocker) {
    return t("payment_mode_not_available_discount_blocker");
  }

  const hasShippingBlocker = reasons.some((e) => e.type === "SHIPPING_HANDLES");
  if (hasShippingBlocker) {
    return t("payment_mode_not_available_shipping_blocker", { mode_title: modeTitle });
  }

  return t("payment_mode_not_available");
};

export const getPayButtonText = (modeData: any) => {
  if (!modeData) return "";
  if (modeData.mode === "COD") return t("cod_pay_x", { amount: currencyFormatter(modeData.amount) });
  if (modeData.mode === "SPLIT_COD")
    return t("split_cod_pay_amount", {
      current_amount: currencyFormatter(modeData.amount),
      cod_amount: currencyFormatter(modeData.metadata?.cod_amount),
    });
  return t("pay_x_amount", { amount: currencyFormatter(modeData.amount) });
};

export const triggerSuccessEvent = () => {
  if (typeof window !== "undefined" && !window.localStorage.getItem("payment_success_flag")) {
    localStorage.setItem("payment_success_flag", "true");
  }
};
/**
 * Finds the "mode_specific_cart_item" value of the first payment method in the list of methods
 * where both "is_freebie" and "is_prepaid" are true in the "discount_metadata".
 *
 * @param {Object[]} data - An array of JSON objects.
 * @returns {Object[] | null} - The "mode_specific_cart_item" array or null if no match is found.
 */
export function findModeSpecificCartAlteration(data: [any]) {
  if (!data || !Array.isArray(data) || !Boolean(data?.length)) return null;
  for (const obj of data) {
    if (obj.discount_metadata?.is_freebie && obj.discount_metadata?.is_prepaid) {
      return true;
    }
  }
  return null;
}

export const getPrepaidDiscountData = (
  paymentMethods: any[],
): {
  maxDiscountMethod: any;
  maxPrepaidDiscountText?: string | null;
  maxDiscountCoupon?: any;
  hasFreebie?: boolean;
  freebieMethod?: any;
} => {
  try {
    let maxDiscount = 0;
    let maxDiscountMethod: any = null;
    let freebieMethod: any = null;
    let allDiscounts = paymentMethods
      ?.filter((method) => method.discount_metadata?.is_prepaid)
      ?.map((method) => method.discount_metadata?.coupon_metadata?.concession_amount ?? 0);

    paymentMethods.forEach((method) => {
      if (method.discount_metadata?.coupon_metadata?.concession_amount > maxDiscount) {
        maxDiscount = method.discount_metadata?.coupon_metadata?.concession_amount;
        maxDiscountMethod = method;
      }
      if (method.discount_metadata?.is_freebie && !Boolean(freebieMethod)) {
        freebieMethod = method;
      }
    });

    const maxDiscountCoupon = maxDiscountMethod?.discount_metadata?.coupon_metadata;

    const discountType: "FLAT" | "PERCENTAGE" = maxDiscountCoupon?.deduction_type;
    const discount: number = maxDiscountCoupon?.concession_amount;

    const discountTypeText = discountType === "FLAT" ? `₹${discount}` : `${discount}%`;
    const maxPrepaidDiscountText =
      Math.max(...allDiscounts) === Math.min(...allDiscounts)
        ? t("x_prepaid_discount", { amount: discountTypeText })
        : t("upto_x_discount", { amount: discountTypeText });

    return {
      maxDiscountMethod,
      maxDiscountCoupon,
      maxPrepaidDiscountText: Boolean(discount) ? maxPrepaidDiscountText : null,
      hasFreebie: Boolean(freebieMethod),
      freebieMethod,
    };
  } catch (e) {
    return {
      maxDiscountMethod: null,
    };
  }
};
