import { loadScript } from "lib/utils/helpers";

const PAYTM_PG_URL = "https://secure.paytmpayments.com/merchantpgpui/checkoutjs/merchants";

export const loadPaytmDefaultSDK = async (mid: string) => {
  try {
    const response = await loadScript(`${PAYTM_PG_URL}/${mid}.js`, async () => {
      await onPaytmDefaultSdkLoad();
    });
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const onPaytmDefaultSdkLoad = () =>
  new Promise((resolve, reject) => {
    try {
      (window as any).Paytm.CheckoutJS.onLoad(() => resolve({}));
    } catch (e) {
      throw reject(e);
    }
  });

interface PaytmDefaultPayment {
  orderId: string;
  token: string;
  amount: number;
}
export const initializePaytmDefaultPayment = async ({ orderId, token, amount }: PaytmDefaultPayment) => {
  const config: any = {
    root: "",
    flow: "DEFAULT",
    data: {
      orderId: orderId,
      token: token,
      tokenType: "TXN_TOKEN",
      amount: amount,
    },
    handler: {
      notifyMerchant: function (eventName: any, data: any) {
        console.log("notifyMerchant handler function called");
        console.log("eventName => ", eventName);
        console.log("data => ", data);
      },
    },
  };
  if ((window as any).Paytm && (window as any).Paytm.CheckoutJS) {
    (window as any).Paytm.CheckoutJS.init(config)
      .then(function onSuccess() {
        (window as any).Paytm.CheckoutJS.invoke();
      })
      .catch(function onError(error: any) {
        console.log("Error => ", error);
      });
  }
};
