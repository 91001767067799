import { addAlpha, getColorByBgColor, hexToRgba } from "lib/utils/helpers";
import { trustBadges } from "lib/utils/constants";
import clockImg from "assests/images/clock.svg";
import globeImg from "assests/images/globe.svg";
import lockImg from "assests/images/lock.svg";
import smileImg from "assests/images/smile.svg";
import starImg from "assests/images/star.svg";
import truckImg from "assests/images/truck.svg";
import returnImg from "assests/images/return.svg";

export const customBranding = (
  primaryColor?: string,
  secondaryColor?: string,
  primaryTextColor?: string,
  isSso?: boolean,
) => {
  if (Boolean(primaryColor) && typeof primaryColor !== "undefined") {
    document?.documentElement?.style?.setProperty("--flo-primary-dark-color", `${primaryColor}`);
    document?.documentElement?.style?.setProperty(
      "--flo-primary-light-color",
      `${addAlpha(primaryColor.substring(0, 7), 0.3)}`,
    );
    document?.documentElement?.style?.setProperty(
      "--flo-primary-lighter-color",
      `${addAlpha(primaryColor.substring(0, 7), 0.1)}`,
    );
    document?.documentElement?.style?.setProperty(
      "--flo-primary-with-alpha-05",
      `${hexToRgba(primaryColor.substring(0, 7), 0.05)}`,
    );
    document?.documentElement?.style?.setProperty(
      "--flo-primary-with-alpha-2",
      `${hexToRgba(primaryColor.substring(0, 7), 0.2)}`,
    );
    if (!isSso) {
      document.body.style.backgroundColor = "var(--flo-primary-lighter-color)";
    } else {
      document?.documentElement?.style?.setProperty("--popup-font-family", '"ShopfloPopupFont"');
    }

    const primaryColorContrast = primaryTextColor ?? getColorByBgColor(primaryColor);
    document?.documentElement?.style?.setProperty("--flo-primary-btn-text-color", primaryColorContrast);
  }

  Boolean(secondaryColor) &&
    document?.documentElement?.style?.setProperty("--brand-secondary-color", secondaryColor ?? "");
};

export const trustBadgeImgConfig = {
  [trustBadges.CLOCK]: clockImg,
  [trustBadges.RETURN]: returnImg,
  [trustBadges.GLOBE]: globeImg,
  [trustBadges.LOCK]: lockImg,
  [trustBadges.STAR]: starImg,
  [trustBadges.TRUCK]: truckImg,
  [trustBadges.SMILE]: smileImg,
};
