import { DiscountedProductSelectorItemType, DiscountedProductSelectorProductType } from "lib/types/checkout";
import React from "react";
import { Disclosure } from "@headlessui/react";
import DiscountedProductCard from "./DiscountedProductCard";
import { ChevronDown } from "react-feather";
import { useLocale } from "lib/hooks/useLocale";

interface DiscountedProductGroupProps {
  key: string;
  productGroup: DiscountedProductSelectorItemType;
  groupIndex: number;
  openState: boolean[];
  setOpenState: (openState: boolean[]) => void;
}

const DiscountedProductGroup: React.FC<DiscountedProductGroupProps> = ({
  key,
  productGroup,
  groupIndex,
  openState,
  setOpenState,
}) => {
  const { t } = useLocale();

  return (
    <>
      <Disclosure
        key={key}
        defaultOpen={openState[groupIndex]}
        refName={`discounted-product-group-${groupIndex}`}
        as={React.Fragment}>
        {({ open, close }) => (
          <>
            <Disclosure.Button className="flex justify-between p-3 items-center w-full text-coal-dark font-medium text-base">
              <p>
                {open
                  ? t("select_any_x", {
                      x: productGroup.quantity,
                    })
                  : t("select_any_x_from_below_y_items", {
                      x: productGroup.quantity,
                      y: productGroup?.products?.length,
                    })}
              </p>
              <div className="flex justify-center items-center space-x-2">
                {open && (
                  <span className="text-sm font-normal text-coal-dark">
                    {t("x_out_of_y_selected", {
                      x: productGroup.selectedQuantity,
                      y: productGroup.quantity,
                    })}
                  </span>
                )}
                <ChevronDown
                  className={`${open ? "-rotate-180 transform" : ""} h-5 w-5 transition duration-200`}
                />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="w-full p-3 flex flex-col space-y-3">
              {productGroup?.products?.map(
                (product: DiscountedProductSelectorProductType, pIndex: number) => (
                  <DiscountedProductCard
                    key={`discounted-product-card-${pIndex + 1}`}
                    selectedProduct={product}
                    groupIndex={groupIndex}
                    productIndex={pIndex}
                  />
                ),
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="w-full border-b border-gray-light" />
    </>
  );
};

export default DiscountedProductGroup;
