/** Class for creating custom error wrappers
 * Shouled be used to wrap errors with extra data
 */
class CustomError<T> extends Error {
    extraData: T;
  
    constructor(message: string, extraData: T) {
      super(message);
      this.extraData = extraData;
    }
}

export default CustomError;
