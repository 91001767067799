export type AutocompleteAttribute =
  | "off"
  | "name"
  | "email"
  | "username"
  | "new-password"
  | "current-password"
  | "organization"
  | "street-address"
  | "address-line1"
  | "address-line2"
  | "address-line3"
  | "shipping address-level2"
  | "shipping address-level1"
  | "shipping street-address"
  | "shipping locality"
  | "shipping region"
  | "shipping postal-code"
  | "shipping country"
  | "country"
  | "country-name"
  | "postal-code"
  | "bday"
  | "tel"
  | "url"
  | "cc-name"
  | "cc-number";


  export const enum PublishMessageOrigin {
    CART = "CART",
  } 
