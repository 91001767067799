import { Dialog } from "@headlessui/react";
import MarketingConsent from "components/common/MarketingConsent";
import { DialogBody, DialogHeader, GenericDialog } from "components/common/dialogs/GenericDialog";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import useForm, { FormField } from "lib/hooks/useForm";
import { useLocale } from "lib/hooks/useLocale";
import { errorToast } from "lib/utils/toasters";
import React, { useCallback, useEffect, useState } from "react";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import TrustBadgeGray from "assests/images/trust-badge-gray.svg";
import "react-phone-number-input/style.css";
import { CartDialogType } from "lib/types/cart";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { useSearchParams } from "react-router-dom";
import { invokeTruecallerDeeplink } from "lib/third-party/truecaller";
import { OTPVerificationDetails, UserLoginType } from "lib/types/user";
import { addDefaultCountryCode, inIframe, publishPostMessage } from "lib/utils/helpers";
import { analyticsEvents, analyticsTypes, constants, eventTypes } from "lib/utils/constants";
import { OTPDeliveryType, sendOtpWithPhoneNumber } from "lib/utils/auth";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { getRequest, putRequest } from "lib/core/apiClient";
import useInterval from "lib/hooks/useInterval";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import useReCaptcha from "lib/hooks/useReCaptcha";

interface CartAuthenticationDialogProps {
  open: boolean;
  setDialog: (dialog: CartDialogType) => void;
  context?: "SSO" | "AUTH";
}

let isPhoneInputFirstFocus = false;
let pollCounter = 1;

const CartAuthenticationDialog: React.FC<CartAuthenticationDialogProps> = ({
  open,
  setDialog,
  context = "SSO",
}) => {
  const { t } = useLocale();

  const {
    actions: { login },
    state: { thirdPartyAuth },
  } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    actions: { setUserLoginType },
    state: { user },
  } = useUserContext();
  const {
    state: { originUrl, checkoutId, checkoutValidations, cartDialog, checkoutItems, appliedCoupons, billing },
    actions: { setCheckoutValidations },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { generateReCaptchaToken } = useReCaptcha();
  const [searchParams, setSearchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phoneNumber");

  const [phoneInputFocused, setPhoneInputFocused] = useState<boolean>(false);
  const [isLoginStatusPolling, setIsLoginStatusPolling] = useState<boolean>(false);
  const [authRequestId, setAuthRequestId] = useState<string>("");

  const {
    handleSubmit,
    inputProps,
    setValueOf,
    setErrors,
    handleFieldChange,
    state: { values, errors },
  } = useForm({
    initialState: { phoneNumber: phoneNumber ?? "" },
    validationSchema: {
      phoneNumber: {
        required: t("invalid_phone_number"),
        phone: t("invalid_phone_number"),
        formatters: ["PHONE"],
      },
    },
  });

  useEffect(() => {
    if (!Boolean(user?.phone)) return;
    setValueOf("phoneNumber", user?.phone);
  }, [user]);

  // Handles Phone number submission
  const handlePhoneNumberSubmit = async ({ phoneNumber }: { phoneNumber: string }) => {
    if (!(isPossiblePhoneNumber(phoneNumber) && isValidPhoneNumber(phoneNumber))) {
      errorToast(t("invalid_phone"));
      return;
    }
    try {
      setIsLoading(true);
      setErrors({ phoneNumber: { status: false }, otp: { status: false, showAlert: false } });
      setValueOf("otp", "");
      let verificationDetails: OTPVerificationDetails = {
        identifier: addDefaultCountryCode(phoneNumber),
        merchantId: merchant?.merchantId ?? "",
        domain: inIframe() ? originUrl : constants?.CHECKOUT_HOST,
      };

      let ssoRequestId;
      let contextMetada: OTPDeliveryType = {
        context: "AUTH",
      };
      if (context === "SSO") {
        ssoRequestId = searchParams.get("request-id");
        if (ssoRequestId) {
          contextMetada = {
            context: "SSO",
            requestId: ssoRequestId,
          };
        }
      }
      const reCaptchaToken = await generateReCaptchaToken("login");
      const result = await sendOtpWithPhoneNumber(verificationDetails, contextMetada, reCaptchaToken);
      setIsLoading(false);
      if (!Boolean(result)) return;
      const isExistingUser: boolean = result.otp_required ?? result.existing_customer;
      // setContextId(result.context_id);
      searchParams.set("ctxId", result.context_id);
      searchParams.set("phoneNumber", phoneNumber);
      setSearchParams(searchParams);

      //Phone entered event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_AUTH_PHONE_ENTERED,
        eventType: "click",
        metaData: {
          userType: isExistingUser ? "repeat" : "guest",
        },
        context: "cart",
      });

      if (!isExistingUser) {
        const authToken = result.token?.access_token;
        const expiry = result.token?.access_token_expires_at;
        login(authToken, "", expiry);
        await putRequest(`/checkout/v1/cart/${checkoutId}/checkout`, {});
        publishPostMessage(eventTypes.CART_TO_CHECKOUT, {
          checkoutId: checkoutId,
          lineItems: checkoutItems,
          cartTotal: billing.total_payable,
          coupons: appliedCoupons,
        });
        setDialog(null);
        setUserLoginType(analyticsEvents.FLO_GUEST_LOGIN as UserLoginType);
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_GUEST_LOGIN,
          eventFor: [analyticsTypes.SF_ANALYTICS],
          eventType: "flo_action",
          metaData: {
            userData: {
              type: analyticsEvents.FLO_GUEST_LOGIN,
            },
            authSource: "SHOPFLO",
          },
          context: "cart",
        });
        return;
      }
      cartDialog === "cartAuthentication" ? setDialog("cartOtpVerification") : setDialog("otpVerification");
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_AUTH_OTP_LOADED,
        eventType: "load",
        context: "cart",
      });
    } catch (err: any) {
      setIsLoading(false);
      if (err?.response?.status === 400) {
        setErrors({
          phoneNumber: {
            status: true,
            message: t("invalid_phone_number"),
          },
        });
      } else {
        errorToast(t("otp_fetch_failed"));
      }
    }
  };

  const onPhoneInputFocus = () => {
    setPhoneInputFocused(true);
    if (Boolean(values?.phoneNumber?.length) || !thirdPartyAuth?.length || isPhoneInputFirstFocus) return;
    const truecallerRequest = thirdPartyAuth[0];
    if (!truecallerRequest) return;
    const authProvider = truecallerRequest.provider;

    switch (authProvider) {
      case "TRUECALLER": {
        setAuthRequestId(truecallerRequest.requestId);
        isPhoneInputFirstFocus = true;
        invokeTruecallerDeeplink(truecallerRequest.redirectUrl);
        setIsLoginStatusPolling(true);
        break;
      }
    }
  };

  const pollThirdPartyAuthStatus = useCallback(async () => {
    if (!authRequestId) {
      stopAuthPolling();
      return;
    }
    let statusResponse;
    try {
      statusResponse = await getRequest(`/auth-callback/${authRequestId}/status`, "AUTH");
      if (statusResponse.status === "VERIFIED") {
        stopAuthPolling();
        const authToken = statusResponse?.metadata?.token_response?.access_token;
        const authTokenExpiry = statusResponse?.metadata?.token_response?.access_token_expires_at;
        const refreshToken = statusResponse?.metadata?.token_response?.refresh_token;
        const refreshTokenExpiry = statusResponse?.metadata?.token_response?.refresh_token_expires_at;
        const isExistingUser = statusResponse?.metadata?.token_response?.existing_user;
        login(authToken, refreshToken, authTokenExpiry, refreshTokenExpiry);
        setUserLoginType(
          isExistingUser
            ? (analyticsEvents.FLO_RETURN_USER_LOGIN as UserLoginType)
            : (analyticsEvents.FLO_GUEST_LOGIN as UserLoginType),
        );
        sendAnalyticsEvent({
          eventName: isExistingUser ? analyticsEvents.FLO_RETURN_USER_LOGIN : analyticsEvents.FLO_GUEST_LOGIN,
          eventType: "flo_action",
          metaData: {
            userData: {
              type: isExistingUser ? analyticsEvents.FLO_RETURN_USER_LOGIN : analyticsEvents.FLO_GUEST_LOGIN,
            },
            authSource: "TRUECALLER",
          },
          context: "cart",
        });

        return;
      }
      if (statusResponse.status === "FAILED" || statusResponse.status === "USER_REJECTED") {
        stopAuthPolling();
        return;
      }
      if (pollCounter >= 30) {
        stopAuthPolling();
        return;
      }
      pollCounter++;
    } catch (e) {
      console.error(e);
      stopAuthPolling();
    } finally {
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_TRUECALLER_ACTION,
        eventType: "flo_action",
        metaData: {
          action: {
            type: statusResponse.status,
          },
        },
        context: "cart",
      });
    }
  }, [authRequestId]);

  const stopAuthPolling = () => {
    pollCounter = 1;
    setIsLoginStatusPolling(false);
  };

  useInterval(pollThirdPartyAuthStatus, isLoginStatusPolling ? 1500 : null);

  return (
    <GenericDialog
      isOpen={open}
      setIsOpen={() => {
        setCheckoutValidations({
          ...checkoutValidations,
          cartLoginPopup: {
            isValid: true,
          },
        });
        setDialog(null);
      }}
      translateAxis="y"
      customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
      dialogOverlay={true}
      closeOnOverlayClick={true}>
      <DialogHeader className="px-4">
        <Dialog.Title as="div" className="flex h-full space-y-0.5 w-full flex-col bg-white py-4">
          <h2 className="inline-flex gap-2 text-lg font-semibold text-carbon-dark">{t("almost_there")}</h2>
          <p className="text-sm font-normal text-coal-light">
            {checkoutValidations?.cartLoginPopup?.isValid
              ? t("verify_phone_number_to_place_order")
              : checkoutValidations?.cartLoginPopup?.metadata?.message}
          </p>
        </Dialog.Title>
      </DialogHeader>
      <DialogBody className={`flex h-96 flex-col`}>
        <div className="flex w-full flex-row items-center justify-center p-3">
          <form
            onSubmit={handleSubmit(handlePhoneNumberSubmit)}
            className="flex w-full flex-col items-center gap-3">
            <PhoneInput
              defaultCountry="IN"
              {...inputProps(FormField.phoneNumber, {
                onChange: (value: string) => {
                  handleFieldChange(FormField.phoneNumber, value);
                },
                onFocus: () => onPhoneInputFocus(),
                onBlur: () => {
                  setPhoneInputFocused(false);
                  // setIsLoginStatusPolling(false);
                },
              })}
              value={user?.phone ?? ""}
              countries={merchant?.addressConfig?.enabledCountries}
              id="flo__auth__phoneInput"
              smartCaret={false}
              className={`placeholder-sm peer flex h-[3.25rem] w-full appearance-none rounded-xl border border-gray-light px-3 text-base placeholder-transparent
                ${errors.phoneNumber?.status && "border-ouch"}
                ${
                  Boolean(values?.phoneNumber?.length) && !errors?.phoneNumber?.status
                    ? "border-[#D5E7DB]"
                    : ""
                } ${
                phoneInputFocused &&
                "border-[1px] !border-primary-dark px-[12px] outline-none ring-[2px] ring-primary-light"
              }`}
              placeholder={t("phone_number")}
              international={true}
              countryCallingCodeEditable={false}
              autoFocus={false}
              limitMaxLength
              error={errors.phoneNumber?.message}
            />
            {errors.phoneNumber?.status && (
              <p
                className={`mt-1 w-full px-3 text-center font-normal ${
                  errors.phoneNumber?.status ? "self-start text-sm text-ouch" : "text-xs text-coal-light"
                }`}>
                {errors.phoneNumber?.status && errors.phoneNumber?.message}
              </p>
            )}
            <PrimaryButton
              buttonText={t("verify_otp")}
              isLoading={isLoading}
              id="flo__auth__loginButton"
              type="submit"
            />

            <MarketingConsent />

            <img src={TrustBadgeGray} className="h-4" alt={"shopflo-logo"} />
          </form>
        </div>
      </DialogBody>
    </GenericDialog>
  );
};

export default CartAuthenticationDialog;
