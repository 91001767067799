export const GSTIN_CODE_TO_STATE_CODES = {
  "01": { state_code: "JK", name: "Jammu & Kashmir" },
  "02": { state_code: "HP", name: "Himachal Pradesh" },
  "03": { state_code: "PB", name: "Punjab" },
  "04": { state_code: "CH", name: "Chandigarh" },
  "05": { state_code: "UT", name: "Uttarakhand" },
  "06": { state_code: "HR", name: "Haryana" },
  "07": { state_code: "DL", name: "Delhi" },
  "08": { state_code: "RJ", name: "Rajasthan" },
  "09": { state_code: "UP", name: "Uttar Pradesh" },
  "10": { state_code: "BH", name: "Bihar" },
  "11": { state_code: "SK", name: "Sikkim" },
  "12": { state_code: "AR", name: "Arunachal Pradesh" },
  "13": { state_code: "NL", name: "Nagaland" },
  "14": { state_code: "MN", name: "Manipur" },
  "15": { state_code: "MI", name: "Mizoram" },
  "16": { state_code: "TR", name: "Tripura" },
  "17": { state_code: "ME", name: "Meghalaya" },
  "18": { state_code: "AS", name: "Assam" },
  "19": { state_code: "WB", name: "West Bengal" },
  "20": { state_code: "JH", name: "Jharkhand" },
  "21": { state_code: "OR", name: "Odisha" },
  "22": { state_code: "CT", name: "Chattisgarh" },
  "23": { state_code: "MP", name: "Madhya Pradesh" },
  "24": { state_code: "GJ", name: "Gujarat" },
  "25": { state_code: "DD", name: "Daman & Diu" },
  "26": { state_code: "DN", name: "Dadra & Nagar Haveli" },
  "27": { state_code: "MH", name: "Maharashtra" },
  "28": { state_code: "AP", name: "Andhra Pradesh (Before Division)" },
  "29": { state_code: "KA", name: "Karnataka" },
  "30": { state_code: "GA", name: "Goa" },
  "31": { state_code: "LD", name: "Lakshadweep" },
  "32": { state_code: "KL", name: "Kerala" },
  "33": { state_code: "TN", name: "Tamil Nadu" },
  "34": { state_code: "PY", name: "Puducherry" },
  "35": { state_code: "AN", name: "Andaman & Nicobar Islands" },
  "36": { state_code: "TS", name: "Telangana" },
  "37": { state_code: "AP", name: "Andhra Pradesh (Newly Added)" },
  "38": { state_code: "LA", name: "Ladakh" },
  "97": { state_code: "OT", name: "Other Territory" },
  "99": { state_code: "CJ", name: "Centre Jurisdiction" }
};